<template>
  <div class="box">
    <!-- 美妆 -->
<div id="box-img" :class="[index==0?'box-0':index==1?'box-1':index==2?'box-2':index==3?'box-3':index==4?'box-4':index==5?'box-5':'']" v-for="(item,index) in imageList" v-lazy:background-image="item.img" :key="index" />
  </div>
</template>

<script>
  export default {
    name:"Beauty",
    data(){
      return {
        imageList:[
          // require('../assets/images/1.')
        ],
      }
    },
    created(){
      //  let arr = [
      //   'height:1278px','height:1166px','height:2306px','height:2584px','height:1350px','height:1524px'
      // ]     
      let arr = [
        'height:639px','height:583px','height:1153px','height:1292px','height:675px','height:762px'
      ]
      for (let index = 1; index <= 6; index++) {
        // obj.img = require(`../assets/images/${index}.png`)
        this.imageList.push(
         {img : require(`../../assets/images/${index}.png`),height: arr[index-1]}
        )
      }
    }
  }
</script>

<style lang="scss" scoped>
  // @mixin bg-image($url) {
  //   background-image: url($url + "@1x.png");
  //   @media (-webkit-min-device-pixel-ratio: 2),(min-device-pixel-ratio: 2) {
  //     background-image: url($url + "@2x.png")
  //   }
  //   @media (-webkit-min-device-pixel-ratio: 3),(min-device-pixel-ratio: 3) {
  //     background-image: url($url + "@3x.png")
  //   }
  // }
  .box {
    // @include bg-image('../assets/images/hd');
    // background-size: 100% 5103px;
    // background-repeat: no-repeat;
    // width: 100%;
    // height: 5103px;
    #box-img {
      // width: 370px;
      background-size: 100% auto;
      background-repeat: no-repeat;
    }
    .box-0 {
      height: 639px;
    }
    .box-1 {
      height: 583px;
    }
    .box-2 {
      height: 1153px;
    }
    .box-3 {
      height: 1292px;
    }
    .box-4 {
      height: 675px;
    }
    .box-5 {
      height: 762px;
    }
  }
</style>